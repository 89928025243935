import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import qs from "qs"

// import { BookingContext } from "../../context/BookingContext"
// import { StaticImage, getImage } from "gatsby-plugin-image"

import { numberWithCommas } from "../../utils/formatUtil"

export default function Hit({ hit: property, ...params }) {
  const urlParams = qs.stringify(params)

  const href = `/property/${property.slug.current}?${urlParams}&price=${property.price}`

  return (
    <div className="font-property bg-white shadow-sm hover:shadow-xl transition duration-300 ease-in-out grid grid-cols-12 overflow-hidden h-auto md:h-full">
      <Link to={href} className="col-span-12 h-56 ">
        {property.images[0]?.asset?.url && (
          <img
            src={property.images[0].asset.url}
            className="h-56  w-full object-fill"
            alt={property.name}
          />
        )}
      </Link>
      <div className="px-4 pt-2 pb-4 col-span-12">
        <div className="font-bold flex items-center justify-between">
          <div>
            <Link to={href}>
              <h3 className="font-property text-lg">{property.name}</h3>
            </Link>
            <span className="font-property font-thin ">{property.area}</span>
          </div>

          <div className="font-semibold flex space-x-2 items-center justify-center">
            {property.bedrooms && (
              <div className="bg-gray-100 py-2 px-4 rounded-full text-xs flex space-x-3 items-center">
                <StaticImage
                  placeholder="tracedSVG"
                  src="../../../static/img/bedrooms.svg"
                  width={15}
                  alt="bedroom"
                />
                <span>{property.bedrooms}</span>
              </div>
            )}
            {property.bathrooms && (
              <div className="bg-gray-100 py-2 px-4 rounded-full text-xs flex space-x-3 items-center">
                <StaticImage
                  placeholder="tracedSVG"
                  src="../../../static/img/bathrooms.svg"
                  width={15}
                  alt="bathroom"
                />
                <span>{property.bathrooms}</span>
              </div>
            )}
          </div>
        </div>
        <div className="py-3">
          {/* <span className="bg-black text-white text-xs px-2 py-px font-medium rounded-lg">
                    Included
                  </span> */}
          <p className="font-light text-gray-400 text-sm  line-clamp-5 lg:line-clamp-3">
            {property.highlight}
          </p>
          {/* <div className="grid grid-cols-2 items-center text-xs text-gray-300 py-2">
            {property.propertyFeatures.slice(0, 2).map(({ name }, key) => (
              <span key={key}>{name}</span>
            ))}
          </div> */}
        </div>
        <div className="font-semibold flex items-center justify-between">
          <div>
            <div className="grid justify-items-center items-end font-sans">
              {property.price ? (
                <h4 className="text-3xl font-semibold">
                  €{numberWithCommas(property.price)}
                </h4>
              ) : (
                <h4 className="text-xl font-semibold">unavailable</h4>
              )}
              <span className="text-gray-400 font-light text-sm">
                for your stay
              </span>
            </div>
          </div>
          <div className="filter drop-shadow-xl text-sm font-light outline-none focus:outline-none px-6 py-3 border bg-black text-white rounded-full">
            <Link to={href} className="font-normal hidden md:block">
              Browse this Villa
            </Link>

            {/* <button
              className="filter drop-shadow-xl text-sm font-light outline-none focus:outline-none px-6 py-3 border bg-black text-white rounded-full"
              // onClick={() => bookingContext.addProperty(property)}
            >
              Begin Booking
            </button> */}
          </div>
        </div>
      </div>
    </div>
  )
}
